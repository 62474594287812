.upper-footer {
  background: var(--primary-color);
  padding: 75px 0;
  min-height: 300px;

  section {
    margin: 0 auto;
    width: 50%;

    @include breakpoint(medium-screens) {
      width: 100%;
    }
    @include breakpoint(handhelds) {
      width: 100%;
    }
  }

  ul {
    list-style-type: none;
    margin-block-start: 0;
    padding-inline-start: 0;

    @include breakpoint(medium-screens) {
      margin: 0 0 2rem 0;
    }
    @include breakpoint(handhelds) {
      margin: 0 0 2rem 0;
    }
  }
}

footer {
  position: relative;
  background: var(--white);
  color: var(black);
  font-size: 14px;

  .copyright-text {
    padding: 10px 0 0;
    text-align: center;
  }
}
