/*------------------------------------------------------------------------------------------------- 
    Navigation
------------------------------------------------------------------------------------------------- */

nav {
  position: relative;
  width: 100%;
}

.navbar-brand {
  display: inline-flex;
}

.navbar-expand-lg {
  .navbar-nav {
    justify-content: end;
    flex-grow: 0;

    .nav-link {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
}

.bg-light {
  background-color: var(--white) !important;
  border-bottom: 1px solid var(--box-schadow-cards);
}
