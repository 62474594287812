/*------------------------------------------------------------------------------------------------- 
    Card
------------------------------------------------------------------------------------------------- */

.card {
  position: relative;
  max-width: 400px;
  height: auto;
  background: var(--white);
  overflow: hidden;
  z-index: 10;

  @include breakpoint(handhelds) {
    min-width: 100%;
  }

  figure {
    position: relative;
    display: flex;
    justify-content: center;
    height: 400px;
    margin: 0;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;

    img {
      position: relative;
      display: block;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      @include transition(transform 0.4s);
    }

    &:hover {
      img {
        opacity: 0.6;
        -webkit-transform: translateX(25%);
        -moz-transform: translateX(25%);
        -ms-transform: translateX(25%);
        transform: translateX(25%);
      }

      figcaption {
        opacity: 1;
        -webkit-transform: rotateY(0deg);
        -moz-transform: rotateY(0deg);
        transform: rotateY(0deg);
        @include transition(transform 0.4s, opacity 0.1s);
      }
    }

    figcaption {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 20px;
      background: var(--primary-color);
      height: 100%;
      width: 50%;
      opacity: 0;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: rotateY(-90deg);
      -moz-transform: rotateY(-90deg);
      transform: rotateY(-90deg);
      @include transition(transform 0.4s, opacity 0.1s 0.3s);

      h4 {
        display: block;
        color: var(--white);
      }

      .btn {
        padding: 0.375rem 1.9rem;
      }
    }
  }
}

.all-cards-same-size {
  flex: 1 1 400px;

  @include breakpoint(handhelds) {
    flex: 1 1 100%;
  }
}

.hidden-flexbox {
  flex-grow: 1;

  .card {
    height: 0 !important;
    background-color: transparent;
    margin: 0;
    padding: 0.1rem;
    border: none;
    @include box-shadow(none);

    &:hover,
    &:focus {
      @include box-shadow(none);
    }
  }
}
