/* ----------------------------------
            Mixins 
---------------------------------- */

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @-ms-keyframes #{$animation-name} {
    @content;
  }

  @-o-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin box-shadow($shadows...) {
  -webkit-box-shadow: $shadows;
  -moz-box-shadow: $shadows;
  -ms-box-shadow: $shadows;
  box-shadow: $shadows;
}

@mixin flex() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin fonts($family, $weight, $size: "", $color: "") {
  font-family: $family;
  font-weight: $weight;
  font-size: $size;
  color: $color;
}

@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}

/*------------------------------------
       Mixin for media queries 
  --------------------------------------*/

//Media Queries Mixin

$break-small: 767px;
$break-large: 992px;

@mixin breakpoint($media) {
  @if $media == handhelds {
    @media only screen and (max-width: $break-small) {
      @content;
    }
  } @else if $media == medium-screens {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-large) {
      @content;
    }
  } @else if $media == medium-screens-portrait {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-large) and (orientation: portrait) {
      @content;
    }
  } @else if $media == wide-screens {
    @media only screen and (min-width: $break-large) {
      @content;
    }
  }
}

/*------------------------------------------------------------------
       Custom Mixin for Bootstrap Sizing with Bootstrap breakpoints
  --------------------------------------------------------------------*/

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
      }
    }
  }
}
