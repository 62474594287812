/*-----------------------------------------
            Font Family 
------------------------------------------*/

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700|PT+Sans:400,700&display=swap");

:root {
  --text-font: "PT Sans", sans-serif;
  --header-font: "Montserrat", sans-serif;
  --button-font: "Montserrat", sans-serif;
}
