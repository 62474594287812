/* ------------------------------------------------------------------------------------------------- 
    Variables (HTML5)
------------------------------------------------------------------------------------------------- */

:root {
  /* Colors */
  --white: #ffffff;
  --black: #000000;
  --primary-color: #6896cf;
  --succes-green: #2eae4a;
  --validation-red-text: #d61919;
  --validation-red-border: #dc3545;
  --validation-bg-red: #f7d1d1;

  /* Text Color */
  --text-color: var(--black);
  --header-color: var(--black);
  --subtitle-color: var(--darkest-grey);

  /* Shadows */
  --shadow-01: rgba(0, 0, 0, 0.1);
  --shadow-02: rgba(0, 0, 0, 0.2);
  --shadow-03: rgba(0, 0, 0, 0.3);
  --shadow-04: rgba(0, 0, 0, 0.4);
  --shadow-05: rgba(0, 0, 0, 0.5);
  --shadow-06: rgba(0, 0, 0, 0.6);
  --shadow-07: rgba(0, 0, 0, 0.7);
  --shadow-08: rgba(0, 0, 0, 0.8);
  --shadow-09: rgba(0, 0, 0, 0.9);
  --shadow-1: rgba(0, 0, 0, 1);
  --box-schadow-cards: rgba(213, 213, 214, 0.6);
  --form-outline-shadow: rgba(141, 115, 108, 0.25);

  /* Border-radius */
  --border-radius-1: 1px;
  --border-radius-2: 2px;
  --border-radius-3: 3px;
  --border-radius-4: 4px;
  --border-radius-5: 5px;
  --border-radius-6: 6px;
  --border-radius-50: 50%;
}

/* Sass Variable */
$primary-color: #6896cf;

/* Path */
$images: "/images/";
$icons-path: "/images/icons/";
$skills: "/images/skills/";
$project-logos: "/images/projects/logos/";

/* Custom Bootstrap sizes (w-50) */
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
);
