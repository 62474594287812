/*------------------------------------------------------------------------------------------------- 
    
    Sass File

-------------------------------------------------------------------------------------------------  */

// Vendors (3rd Party CSS)
@import "../../node_modules/bootstrap/scss/bootstrap";

// Modules and Variables
@import "partials/_base.scss";

// Partials

@import "partials/_buttons.scss";
@import "partials/_card.scss";
@import "partials/_footer.scss";
@import "partials/_forms.scss";
@import "partials/_menu.scss";
