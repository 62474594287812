/*--------------------------------
            Buttons
---------------------------------*/

.btn {
  @include fonts(var(--button-font), 400, 14px);
  padding: 0.375rem 3.4rem;
  @include transition(all 0.3s ease);

  &.disabled,
  &:disabled {
    opacity: 0.35;
  }

  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
  }

  &-black {
    background: var(--black);
    margin-top: 20px;
    color: var(--white);

    &:disabled,
    .disabled {
      &:focus,
      &:hover,
      &:active {
        background: var(--black);
        color: var(--white);
      }
    }

    &:focus,
    &:hover,
    &:active {
      border: 1px solid var(--black);
      background: var(--white);
      color: var(--black);
    }
  }

  &-white {
    background: var(--white);
    border: 1px solid var(--primary-color);
    color: var(--primary-color);

    &:disabled,
    .disabled {
      &:focus,
      &:hover,
      &:active {
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
      }
    }

    &:focus,
    &:hover,
    &:active {
      background: var(--primary-color);
      color: var(--white);
    }
  }

  &-primary-color {
    background: var(--primary-color);
    color: var(--white);

    &:disabled,
    .disabled {
      &:focus,
      &:hover,
      &:active {
        background: var(--primary-color);
        color: var(--white);
      }
    }

    &:focus,
    &:hover,
    &:active {
      border: 1px solid var(--primary-color);
      background: var(--white);
      color: var(--primary-color);
    }
  }

  &-reverse {
    background: var(--primary-color);
    color: var(--white);
    border: 1px solid var(--white);

    @include breakpoint(handhelds) {
      padding: 0.5rem;
    }

    &:disabled,
    .disabled {
      &:focus,
      &:hover,
      &:active {
        background: var(--primary-color);
        color: var(--white);
      }
    }

    &:focus,
    &:hover,
    &:active {
      border: 1px solid var(--white);
      background: var(--white);
      color: var(--primary-color);
    }
  }

  &-link {
    background: transparent;
    color: var(--white);
    border: 1px solid var(--primary-color);

    &:focus,
    &:hover,
    &:active {
      border: 1px solid var(--primary-color);
      background: var(--primary-color);
      color: var(--white);
    }
  }
}
