/*-----------------------------------
		Forms
------------------------------------*/

.input-group {
  &-text {
    color: var(--white);
    text-align: center;
    white-space: nowrap;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }

  &.has-error {
    .input-group-text {
      background-color: var(--validation-red-border);
      border: 1px solid var(--validation-red-border);
    }
    .form-control {
      border-color: var(--validation-red-border);
      background: var(--validation-bg-red);
    }
  }
}

.form-control {
  @include fonts(var(--text-font), 400, 15px, var(--text-color));
  border-color: var(--primary-color);

  &:focus {
    border-color: var(--primary-color);
    @include box-shadow(0 0 0 0.2rem var(--form-outline-shadow));
  }
  &::placeholder,
  &:-ms-input-placeholder,
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:placeholder-shown {
    color: var(--black);
  }
}

textarea.form-control {
  height: 100px;
}

.invalid-feedback {
  display: flex;
  padding: 5px 0 0;
  font-size: 14px;
  color: var(--validation-red-text);
}
