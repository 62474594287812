/*------------------------------------------------------------------------------------------------- 
    
    Base file, set-up the Sass environment

------------------------------------------------------------------------------------------------- */

/*------------------------------------------------------------------------------------------------- 
    Import all Module files
------------------------------------------------------------------------------------------------- */

@import "../modules/_all.scss";

/*------------------------------------------------------------------------------------------------- 
    Generic
------------------------------------------------------------------------------------------------- */
body {
  position: relative;
  height: 100%;
  background: var(--white);
  @include fonts(var(--text-font), 300, 15px, var(--text-color));
  letter-spacing: normal;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  overflow: hidden;
}

header {
  position: relative;
  width: 100%;
  height: 100%;
}

main {
  margin-top: 3rem;
  min-height: 250px;
}

#contact {
  margin: 5rem 0;
  min-height: 400px;
}

h1 {
  @include fonts(var(--header-font), 700, 25px, var(--header-color));
}

h2,
h3,
h4,
h5 {
  @include fonts(var(--header-font), 700, 20px, var(--header-color));
  display: block;
}

h3,
h5 {
  text-transform: uppercase;
}

b,
strong {
  font-weight: bold;
}

.bg-color {
  min-height: 500px;
  background: var(--primary-color);
}

.link {
  color: var(--primary-color);
  text-decoration: none;

  &:focus,
  &:hover,
  &:active {
    color: darken($primary-color, 15%);
    text-decoration: underline;
  }
}

.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-block {
  width: 30%;
  position: absolute;
  top: 25%;
  right: 100px;
  background: var(--white);
  padding: 50px;
  border-radius: 0.25rem;
  opacity: 0.8;

  @include breakpoint(medium-screens) {
    width: 50%;
    top: 15%;
  }

  @include breakpoint(handhelds) {
    width: 70%;
    top: 15%;
  }
}

/*-----------------------------------
	Brand Logo
------------------------------------*/
.brand-logo {
  background: url("#{$images}Logo_nadeelclaim.svg") center center no-repeat;
  width: 70px;
  height: 70px;
  display: inline-block;
  margin: 0.55rem;
}

.brand-word {
  display: inline-flex;
  align-self: center;
  @include fonts(var(--header-font), 700, 25px, var(--header-color));
}

/*-----------------------------------
	Helper classes
------------------------------------*/
.noPadding {
  padding: 0;
}

.noMargin {
  margin: 0;
}

.noBorder {
  border: 0;
}

/*-----------------------------------
	Header background
------------------------------------*/
.header-img {
  background: url("/images/header.jpg") no-repeat center center;
}

.header-blog {
  background: url("/images/header-blog.jpg") no-repeat center center;
}

.header-blog-page {
  background: url("/images/header2.jpg") no-repeat center center;
}

.header-blog,
.header-img,
.header-blog-page {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  background-size: cover;
}

figure {
  width: 100%;
  height: 500px;
  margin: 0;

  @include breakpoint(medium-screens) {
    height: auto;
  }
  @include breakpoint(handhelds) {
    height: auto;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: right center;

    @include breakpoint(medium-screens) {
      object-fit: unset;
      object-position: unset;
    }
    @include breakpoint(handhelds) {
      object-fit: unset;
      object-position: unset;
    }
  }
}

.about {
  padding: 10rem 3rem 0;

  @include breakpoint(medium-screens) {
    padding: 2rem 0;
  }
  @include breakpoint(handhelds) {
    padding: 2rem 0;
  }

  h3 {
    margin: 0;
  }
}

.black-link {
  color: var(--black);
  text-decoration: none;

  &:focus,
  &:hover,
  &:active {
    color: var(--black);
    text-decoration: underline;
  }
}
